import React from 'react';
import Layout from '../components/Layout';
import config from '../config.js';

export default () => {
  const head = {
    favicon: config.site.head.favicon,
    metaTitle: '404 Page Not Found',
    metaDescription: '404 Page Not Found'
  };

  return (
    <Layout className="layout--404" head={head}>
      <div className="error">
        <h1 className="error__title">404 Page Not Found</h1>
      </div>
    </Layout>
  );
}
